import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from "@ngrx/store";

export type AuthState = {
  user: any | null;
  registerError: string | null;
  loginError: string | null;
  registerLoading: boolean;
  loginLoading: boolean;
  token: string | null;
}

export type LoginResponse = {
  token: string;
}

const initialState: AuthState = {
  user: null,
  registerError: null,
  loginError: null,
  registerLoading: false,
  loginLoading: false,
  token: null
};


export const AuthActions = createActionGroup({
  source: 'AuthActions',
  events: {
    'Reset State': emptyProps(),
    'Register': props<{ username: string; password: string }>(),
    // TODO: Full user model
    'Register Success': props<{ user: any }>(),
    'Register Failure': props<{ error: any }>(),
    'Login': props<{ username: string; password: string }>(),
    'Login Success': props<{ token: LoginResponse }>(),
    'Login Failure': props<{ error: any }>(),
    'Set Token': props<{ token: string }>(),
    'Set User': props<{ user: any }>(),
  }
});

export const AuthFeature = createFeature({
  name: 'AuthFeature',
  reducer: createReducer(
    initialState,
    on(AuthActions.resetState, () => initialState),
    on(AuthActions.register, state => ({
      ...state,
      registerLoading: true,
      error: null
    })),
    on(AuthActions.registerSuccess, (state, { user }) => ({
      ...state,
      registerLoading: false,
      registerError: null,
      user,
    })),
    on(AuthActions.registerFailure, (state, { error }) => ({
      ...state,
      registerLoading: false,
      registerError: error.error || 'Registration failed.'
    })),
    on(AuthActions.login, state => ({
      ...state,
      loginLoading: true,
      loginError: null
    })),
    on(AuthActions.loginSuccess, (state, { token }) => ({
      ...state,
      loginLoading: false,
      loginError: null,
      token: token.token
    })),
    on(AuthActions.loginFailure, (state, { error }) => ({
      ...state,
      loginLoading: false,
      loginError: 'Unable to login with the provided credentials.'
    })),
    on(AuthActions.setToken, (state, { token }) => ({ ...state, token })),
    on(AuthActions.setUser, (state, { user }) => ({ ...state, user })),
  ),
});